import styled from 'styled-components';

const NavButton = styled.a `
  background: #3f51b5;
  width: auto;
  padding: 11px 10px;
  border-radius: 3px;
  text-align: center;
  color: #fff;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
`;
export default NavButton;